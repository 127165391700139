.pageBody{
  background-color: var(--grey-1);
}

.container{
  max-width: 1550px;
  padding: 24px 12px 32px;
  margin: auto;
  @media( min-width:769px ){
    flex-wrap: nowrap;
    padding: 24px 12px 32px;
  }
}

.content{

  &:global(.ant-row){

    main{
      margin-bottom: 32px;
    }
    @media( min-width:769px ){
      flex-wrap: nowrap;

      main{
        margin-bottom: 0;
      }
    }
  }
}

.sidebarCol{
  flex: 1;
  @media( min-width:769px ){
    flex: 0 0 440px;
  }
  @media( min-width:1200px ){
    flex: 0 0 500px;
  }
}

.featuredPost{
  display: block;
  margin-bottom: 24px;
}