@import '../../styles/variables';
@import '../../styles/text-typography';
@import '../../styles/responsive';

.container {
  padding: 24px 16px;
  background-color: var(--gray-1-5);
  width: auto;
  margin: 4px;
  box-shadow: 0 1px 2px rgb(8 24 35 / 6%), 0 1px 3px rgb(8 24 35 / 10%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;

  .containerContent {

    .contentIcon {
      width: 48px;
      max-height: 48px;
      border-radius: 50%;
      background-color: $purple-lightest;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;

      svg {
        font-size: 21px;
        height: 1em;
        width: 1em;
      }
    }

    .contentTitle {
      color: $text-blue-dark;
      margin-bottom: 8px;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;

    }

    .contentText {
      color: $text-blue-dark-lightest;
      margin-bottom: 16px;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
    }

    
  }

  .contentLink {
    display: block;
    color: $text-blue-dark-light;
    border-radius: 4px;
    margin-top: 24px;
    text-transform: uppercase;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;

    span {
      margin-left: 4px;
    }
  }

  .contentMore {
    display: inline-block;
    color: white;
    margin-top: 8px;
    padding: 1px 8px;
    background-color: $primary;
    border-radius: 32px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
  }
}

.pricingFeatures {
  padding-top: 80px;

  .pricingFeaturesHeader {
    max-width: 956px;
    margin: auto;
    padding: 0 16px;

    @include responsive(sm) {
      padding: 0 24px;
    }

    h1 {
      color: $text-blue-dark-light;
      font-family: Inter, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;

      @include responsive(md) {
        font-size: 46px;
        line-height: 54px;
      }
    }

    p {
      color: $text-blue-dark-lightest;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.3px;
      text-align: center;
    }
  }

  :global{

    .ant-carousel {
      margin: 0 16px;
  
      @include responsive(sm) {
        margin: 0;
      }
  
      .slick-list {
        padding-left: 0;
  
        @include responsive(sm) {
          padding-left: 24px;
        }
  
        .slick-track {
          display: flex !important;
          
          div {
            height: 100%;
          }
  
          .slick-slide {
            height: inherit !important;
            padding-bottom: 10px;
  
            @include responsive(xs) {
              width: 288px;
            }
  
            @include responsive(sm) {
              width: 260px;
            }
  
            @include responsive(md) {
              width: 344px;
            }
  
            @include responsive(lg) {
              width: 299px;
            }
  
            @include responsive(xl) {
              width: 270px;
            }
  
            @include responsive(xxl) {
              width: 370px;
            }
          }
        }
      }
    }

    .ant-tabs {

      .ant-tabs-nav {
  
        &::before {
          border: 0;
        }
  
        .ant-tabs-tab {
          margin: 0 12px;
        }
  
        .ant-tabs-tab-btn {
          color: $text-blue-dark-lightest;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
  
        }
  
        .ant-tabs-tab-active {
  
          .ant-tabs-tab-btn {
            color: $primary;
          }
        }
  
        .ant-tabs-ink-bar {
          background-color: $primary;
        }
      }
    }
  }
}

.homeBbColor{
  background-color: $blue-lighter;
}
