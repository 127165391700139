.section{
  background-color: var(--grey-1);
  margin-top: 0;

  .title{
    color: var(--grey-10);
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 32px;
    line-height: 46px;
    text-align: left;
    text-align: center;
    @media( min-width:769px ){
      font-size: 38px;
    }
  }
}

.carouselWrapper{
  position: relative;
}

.actionsNav{
  position: absolute;
  top: 50%;
  z-index: 2;
  left: 50%;
  width: 100%;
  transform: translate(-50% , -50%);
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.itemNavArrow{
  pointer-events: all;
}

.itemNavArrowLeft{
  transform: translateX(-5px);
}

.itemNavArrowRight{
  transform: translateX(5px);
}