@import '../../styles/responsive';

.newsListWrapper{
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}

.newsList{
  margin-bottom: 24px;
}

.card{
  position: relative;
  border: 1px solid transparent;
  box-shadow: 0 2px 5px rgba(8 24 35 / 10%);
  border-radius: 4px;
  overflow: hidden;

  img{
    width: 320px;
    height: 300px;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
  }

  .body{
    position: absolute;
    z-index: 1;
    background-color: var(--grey-1);
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;

    .title{
      color: var(--grey-9);
      margin: 0;
      padding: 12px;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
    }

    .descriptionWrapper{
      color: var(--grey-8);
      height: 0;
      transition: height 0.3s ease;
    }

    .description{
      padding: 0 12px;

      :global(.ant-typography){
        font-size: 16px;
        line-height: 6px;
        text-align: left;
        transition: line-height 0.3s ease;
        font-weight: 400;
      }
    }
  }

  &:hover{

    .descriptionWrapper{
      height: 90px;
      transition: height 0.3s ease;
    }

    .description{

      :global(.ant-typography){
        line-height: 24px;
        transition: line-height 0.3s ease;
      }
    }

  }
}