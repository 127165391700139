@import '../styles/text-typography';

.jumbtron {
  background-color: inherit;
  width: 100%;
}

.title{
  margin: 0;
  font-size: 30px;
  color: var(--gray-10);
  font-weight: 600;
  padding-bottom: 16px;
}

.subtitle{
  padding: 0;
  margin: 0;
  color: var(--gray-8);

  @include text-typography(Inter, 16px, normal, 400, 22px, 0, left);
}