@import '../styles/variables';

.container {
  margin-bottom: 24px;


  span {

    .containerItem {
      color: #737585 !important;

      a {
        color: #737585 !important;
      }
    }

    &:last-child {

      .containerItem {
        color: $text-blue-dark-lightest !important;
      }
    }
  }
}
