
.container {
  margin-bottom: 24px;


  span {

    .containerItem {
      color: var(--grey-7);

      a {
        color: var(--grey-7);
      }
    }

    &:last-child {

      .containerItem {
        color: var(--blue-6);
      }
    }
  }
}
