@import '../../styles/responsive';

.categoryTag{

  &:global(.ant-tag){
    padding: 4px 16px;
    font-size: 16px;
    border-radius: 24px;
    margin-bottom: 16px;
    line-height: 24px;
    border-color: var(--gray-9);
  }
}

.itemRecentPost{
  display: block;
  color: var(--gray-9);
  margin-bottom: 16px;

  :hover{
    color: var(--blue-6);
  }

  :global(h3.ant-typography){
    font-size: 16px;
    font-weight: 600;
  }

  img{
    border-radius: 4px;
  }

}