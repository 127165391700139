@import '../../styles/variables';
@import '../../styles/responsive';

.wrapper {
  display: flex;
  border-radius: 4px;
  filter: drop-shadow(0 2px 3px rgb(8 24 35 / 10%));
  flex-direction: column;
  @media( min-width:960px ){
    flex-direction: row;
  }
}

.cover {
  position: relative;
  flex-basis: 30%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
    display: block;
    @media( min-width:960px ){
      border-radius: 4px 0 0 4px;
    }
  }
}

.coverSkeleton {
  padding: 0;
}

.dataWrapper {
  padding: 24px;
  background-color: var(--gray-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 70%;

  @include responsive(xs) {
    width: 100%;
    gap: 24px;
  }

  @include responsive(sm) {
    width: 100%;
    gap: 24px;
  }

  @include responsive(md) {
    width: 100%;
    gap: 28px;
  }
  @include responsive(lg) {
    width: 100%;
    gap: 8px;
  }
  @include responsive(xl) {
    width: 100%;
    gap: 22px;

  }
  @include responsive(xxl) {
    width: 100%;
  }
  
  .head{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .categories {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    overflow-x: hidden;
    padding-bottom: 10px;

    .tag{
      border-radius: 36px;
      border: 1px solid #262b4b;
      padding: 4px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  .title {
    color: var(--gray-9);
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .subtitle {
    color: var(--gray-8);
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  .info {
    color: var(--gray-8);
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    p {
      margin: 0;
      padding: 0;

      svg {
        width: 28px;
        height: 28px;
        fill: var(--gray-8);
      }
    }

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
}

.featuredPost{

  @media( min-width:768px ){

    .dataWrapper {
      flex-basis: 50%;
    }
  
    .cover {
      flex-basis: 50%;
    }

    .title {
      color: var(--gray-9);
      font-size: 30px;
      line-height: 38px;
    }
  }

}