.headActions {
  align-self: flex-end;

  @media (min-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.carouseDots{
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 10px;
}

.carouseDotsItem{
  margin-right: 1px;
  max-height: 4px;
  height: 4px;
  min-width: 16px;
  max-width: 16px;
  width: 100%;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  transition: background-color 0.3s ease;
  background-color: #d0d1d6;
}

.carouseDotsItemActive{
  background-color: var(--blue-6);
}

.actionsNav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navItem {

  &:first-child {
    margin-right: 8px;
  }
}
