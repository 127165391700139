/* stylelint-disable indentation */
@import '../../../styles/responsive';
@import '../../../styles/variables';

.featuresPageContainer{
  padding: 0 16px;

  // overflow: hidden;
}


.featuresTitleContainer{
  text-align: center;
  font-size: 46px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0;
}

.featuresDescriptionContainer{
  margin: 0 0 60px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
}

@include responsive(sm){

  .featuresDescriptionContainer{
    margin: 0 32px 60px;
  }
}

.breadcrumbContainer {
  background-color: #f9f9fa;
  padding: 24px 24px 4px;
  display: flex;
}

.featuresPageItemsContainer{
  height: 100%;
  padding-left: 10px;
  margin-bottom: 20px;
}


.imageContainer{
  position: relative;
  width: 100%;
  height: unset;
}

.featuresPageImage{
  position: sticky;
  top: 100px;
  width: 100%;
  height: unset;
  object-fit: contain;
}

@include responsive(md){
  
  .imageContainer{
    height: unset;
  }

  .featuresPageImage{
    position: sticky;
    top: 100px;
    width: 100%;
    height: unset;
    object-fit: contain;
  }
  
}



.singleFeaturePageItemTitle{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 15px;
}

.singleFeaturePageItemDescription{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.singleFeaturePageItemButton{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  padding: 0;
  margin: 5px 0 15px;
}

.verticalActiveLine{
  height: 100%;
  width: 6px;
  background-color: var(--blue-6);
}