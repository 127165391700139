@import '../../../styles/responsive';
@import '../../../styles/variables';

.container{
  width: 100%;
}

.blogWrap {
  background-color: var(--grey-1);
  padding-top: 24px;
}

.contentWrap {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  max-width: 1552px;
  margin: 0 auto;

  @include responsive(xs) {
    flex-direction: column;
    padding: 16px;
  }
  @include responsive(sm) {
    flex-direction: column;
    padding-bottom: 24px;
  }
  @include responsive(md) {
    flex-direction: row;
  }
  @include responsive(lg) {
    flex-direction: row;
  }
  @include responsive(xl) {
    flex-direction: row;
  }
  @include responsive(xxl) {
    flex-direction: row;
  }
}

.content {
  width: 65%;
  padding-bottom: 32px;

  @include responsive(xs) {
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
  }

  @include responsive(sm) {
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
  }

  @include responsive(md) {
    flex-direction: row;
    width: 50%;
  }
  @include responsive(lg) {
    flex-direction: row;
    width: 65%;
  }
  @include responsive(xl) {
    flex-direction: row;
    width: 65%;
  }
  @include responsive(xxl) {
    flex-direction: row;
    width: 65%;
  }
}

.featuredImg {

  img{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }
}

.categoryTag{

  &:global(.ant-tag){
    padding: 4px 16px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 24px;
    line-height: 24px;
    color: var(--gray-9);
    border-color: var(--gray-9);
  }
}

.articleTagItem{

  &:global(.ant-tag){
    padding: 4px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 2px;
    line-height: 24px;
    color: var(--blue-6);
    background-color: var(--blue-1);
  }
}

.title {
  margin-bottom: 24px;
  color: var(--grey-9);
  font-family: Inter,sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
}

.info {
  display: flex;
  margin-bottom: 38px;

  @include responsive(xs){
    flex-direction: column;
    gap: 16px;
  }
  @include responsive(sm){
    flex-direction: row;
    justify-content: space-between;
  }
  @include responsive(md){
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
  }
  @include responsive(lg){
    flex-direction: row;
    justify-content: space-between;
  }
  @include responsive(xl){
    flex-direction: row;
    justify-content: space-between;
  }
  @include responsive(xxl){
    flex-direction: row;
    justify-content: space-between;
  }
}

.richText {

  p{
    margin: 0;
    margin-bottom: 16px;
  }
}

.richTextWrapper {
  border-bottom: 1px solid var(--grey-4);
  margin: 24px auto;
  max-width: 760px;

  .richTextDiv {

    p,ul,ol {
      color: var(--grey-8);
      margin: 0;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;

      img{
        width: 100%;
        object-fit: contain;
      }

      figure{
        width: 100%;
      }
    }

    ol{
      padding-left: 34px;
    }

    ul{
      padding-left: 24px;
      list-style: circle;
    }

    h1 {
      color: var(--grey-9);
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }

    h2 {
      color: var(--grey-9);
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

.contentImage{
  max-width: 100%;
  margin: 24px 0;
}