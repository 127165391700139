.wrap {
  background: inherit;
  width: 100%;
}

.breadcrumbWrap{
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px 24px 16px;
}

.breadcrumb{
  display: flex;

}

.link{
  color: var(--grey-7);
  text-transform: capitalize;
}