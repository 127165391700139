.sidebarSectionWrapper {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 24px;
}

.header{
  padding: 16px;
  background-color: var(--gray-10);

  h2{
    margin: 0;
  }
}

.title{
  text-transform: capitalize;
  color: var(--grey-2);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.content{
  padding: 24px 16px;
  background-color: var(--grey-1-5);
}
