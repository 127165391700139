@import '../../styles/responsive';
@import '../../styles/variables';

.caq {
  margin: 64px auto;
  padding: 0 16px;

  @include responsive(sm) {
    padding: 0 24px;
  }

  @include responsive(xl) {
    max-width: 1290px;
  }

  h1 {
    color: $text-blue-dark-light;
    margin-bottom: 32px;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;

    @include responsive(sm) {
      font-size: 38px;
      line-height: 46px;
    }
  }

  :global{

    .ant-collapse {
      background-color: transparent;
      border-left: 0;
      border-right: 0;

      .ant-collapse-item {
      
        .ant-collapse-header {
          color: $text-blue-dark-light;
          padding: 24px;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;

          span {
            font-size: 17px;
            transition: transform 0.2s;
            top: 24px;
            padding: 0;
          }

          span.active {
            transform: rotate(180deg);
            color: $primary;
          }

          p {
            margin-right: 24px;
            margin-bottom: 0;
          }
        }

        .ant-collapse-content {
          border-top: none;
          color: $text-blue-dark-lightest;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;

          .ant-collapse-content-box {
            padding: 24px;

            a {
              color: $primary;
              border-bottom: 1px solid $primary;
            }
          }
        }
      }
    }
  }
}
