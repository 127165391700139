.card {
  background-color: var(--grey-1);
  border-radius: 4px;
  margin: 0 10px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(8 24 35 / 10%);
  display: block;
}

.cover {
  width: 100%;
  overflow: hidden;
}

.content{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px;
}

.blogBtn{
  position: absolute;
  right: 24px;
  top: 24px;
  width: 76px;
  height: 40px;
  border-radius: 100px;
  background: #f0ebff;
  color: var(--blue-6);
  text-transform: uppercase;
  text-align: center;
  line-height: 40px;
}

.categoryTag{

  &:global(.ant-tag){
    padding: 4px 16px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 24px;
    line-height: 24px;
    color: var(--gray-9);
    border-color: var(--gray-9);
  }
}
    
.title{
  color: var(--grey-9);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin-top: 12px;
  height: 64px;
}
    
.subtitle{
  color: var(--grey-8);
  overflow: hidden;
  font-family: Inter,sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.info{
  display: flex;
}


