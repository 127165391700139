@import '../../../styles/responsive';
@import '../../../styles/variables';

.jumbotron {
  background-color: #f9f9fa;
  box-shadow: inset 0 -1px 0 #d0d1d6;
  padding: 0 24px;

  @include responsive(md) {
    padding: 24px;
  }
}

.jumbotronTitle{
  font-size: 30px;
  color: var(--gray-9);
  font-weight: 600;
}

.container {
  display: flex;
  flex-direction: column;

  @include responsive(mobile-large) {
    padding: 0 24px;
  }

  @include responsive(tablet) {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto 348px;
  }

  @include responsive(pc-small) {
    grid-template-columns: auto 300px;
  }

  @include responsive(pc) {
    grid-template-columns: auto 368px;
  }

  @include responsive(pc-large) {
    grid-template-columns: auto 500px;
  }
}

.content{
  padding: 0 24px;

  .contentLogs{
    display: flex;
    gap: 16px;

    @include responsive(xs) {
      flex-direction: column-reverse;
    }
    @include responsive(sm) {
      flex-direction: column-reverse;
    }
    @include responsive(md) {
      flex-direction: row;
    }
    
  }
}

.contentNoTabs{
  margin-top: 20px;
}

.emptyLogs {
  margin: 100px 0;
}