@import '../../styles/responsive';
@import '../../styles/variables';

.bottomContent {
  padding: 32px 24px 0;

  .containerTitle {
    color: $text-blue-dark-light;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: center;


    br {
      display: none;
    }
  }

  .containerText {
    color: $text-blue-dark-light;
    padding-top: 16px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: center;


    @include responsive(sm) {
      padding-top: 0;
    }
  }
}

.container {
  background-color: $purple-lightest;

  .containerWrapper {
    display: flex;

    .contentImage {
      display: flex;
      align-items: flex-end;
      width: 102px;

      @include responsive(xs) {
        width: 183px;
      }

      @include responsive(sm) {
        width: 244px;
      }

      @include responsive(md) {
        width: 315px;
      }

      @include responsive(lg) {
        width: 382px;
      }

      @include responsive(xl) {
        width: 510px;
      }

      img {
        width: 100%;
      }
    }

    .containerContent {
      margin-right: 24px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @include responsive(xs) {
        max-width: 344px;
      }

      @include responsive(sm) {
        max-width: 443px;
        margin-left: auto;
      }

      @include responsive(md) {
        max-width: 618px;
      }

      @include responsive(lg) {
        max-width: 662px;
        margin-left: auto;
      }

      @include responsive(xl) {
        max-width: 895px;
      }

      .contentTitle {
        color: $text-blue-dark-light;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: 64px;
        letter-spacing: 0;
        text-align: left;

        br {
          display: none;
        }

        @include responsive(sm) {
          font-size: 30px;
          line-height: 38px;
        }

        @include responsive(md) {
          font-size: 38px;
          line-height: 46px;
        }

        @include responsive(lg) {
          font-size: 46px;
          line-height: 54px;

          br {
            display: initial;
          }
        }

        @include responsive(xl) {
          font-size: 56px;
          line-height: 64px;
        }
      }

      .contentText {
        color: $text-blue-dark-light;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.3px;
        text-align: left;

        @include responsive(xl) {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
}
