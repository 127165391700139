@import '../../styles/variables';
@import '../../styles/responsive';

.container {
  position: relative;

  .containerContent {
    text-align: center;
    padding: 0 16px;

    .contentTitle {
      margin-top: 40px;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      font-family: Inter, sans-serif;
      font-size: 24px;
      line-height: 32px;
      font-weight: normal;

      h2,p {
        font-weight: 600;
        margin: 0 ;

        @include responsive(md) {
          font-size: 30px;
          line-height: 38px;
        }
  
        @include responsive(lg) {
          font-size: 46px;
          line-height: 54px;
        }
      }

      p{
        color: $primary;
        margin: 0 ;
      }
    }

    .contentText {
      color: $text-blue-dark-lightest;
      font-family: Inter, sans-serif;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-align: center;


      @include responsive(md) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .carouselControllerContainer{
    width: 70px;
    margin-left: calc(100% - 80px);
  }

  .planCardsSliderContainer{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: flex-start;

    @include responsive(xxl){
      justify-content: center;
    }
  }

  .goldButtonContainer{
    width: 300px;
    margin: 0 auto;
  }

  .containerCards {
    justify-content: center;
    padding: 0 4px;
    gap: 16px;
    margin-bottom: 20px;
    width: 100%;
    
    @include responsive(md) {
      padding: 0 12px;
    }

    @include responsive(lg) {
      flex-direction: row;
    }
  }

}
