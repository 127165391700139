.share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    color: white;
    border-radius: 50%;
    padding: 8px;
    width: 32px;
    height: 32px;

    &:nth-child(1) {
      background-color: #385898;
    }

    &:nth-child(2) {
      background-color: black;
    }

    &:nth-child(3) {
      background-color: #0073b1;
    }

    &:nth-child(4) {
      background-color: #ff4500;
    }

    &:nth-child(5) {
      background-color: #262b4b;
    }
  }

  .itemLink {
    display: grid;
    place-items: center;

    svg {
      width: 16px;
      height: 16px;
      fill: white;
    }
  }
}