@import '../../styles/responsive';
@import '../../styles/variables';

.container {
  background: inherit;
  width: 100%;
  padding-bottom: 24px;
  box-shadow: inset 0 -1px 0 #d0d1d6;
}

.wrap{
  display: flex;
  max-width: 1600px;
  margin: 0 auto;
  
  
  @include responsive(xs){
    flex-direction: column;
  }
  
  @include responsive(sm) {
    flex-direction: column;
  }
  @include responsive(md) {
    flex-direction: row;
  }
  @include responsive(lg) {
    flex-direction: row;

  }
  @include responsive(xl) {
    flex-direction: row;

  }

  .containerSearch{
    padding: 12px 16px;
    display: flex;
    align-items: flex-end;

    .search {
      border-radius: 4px;
      width: 270px;
      padding: 8px;
      color: var(--gray-6);
      font-family: Inter,sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;

      &:hover {
        border-color: var(--blue-6);
      }

      &:focus {
        border-color: var(--blue-6);
        box-shadow: 0 0 0 2px rgb(var(--blue-6) 8%);
      }
      @include responsive(xs) {
        width: 100%;
      }
      
      @include responsive(sm) {
        width: 528px;
      }
      
      @include responsive(md) {
        width: 242px;
      }

      @include responsive(lg){
        width: 270px;
      }
    }
  }
}

.jumbotron {
  background-color: inherit;
  padding: 0 24px;
  width: 100%;
}
