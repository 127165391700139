/* stylelint-disable indentation */
@import '../../../../sailor-ui/styles/responsive';
@import '../../../../sailor-ui/styles/variables';

.container {
  background-color: #f9f9fa;

  .containerWrapper {
    padding: 0 16px;
    overflow: hidden;
  
    @include responsive(md) {
      padding: 0 24px;
    }
  
    @include responsive(lg) {
      padding-right: 0;
    }
  
    @include responsive(xl) {
      padding: 0;
      padding-left: 122px;
    }
  
    @include responsive(xxl) {
      padding-left: 284px;
    }

    .containerHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include responsive(lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    
      .headContent {
        padding: 16px 0 32px;

        .description{

          p,ul li,ol li{
            color: $text-blue-dark-lightest;
            margin-bottom: 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
          }
        }
    
        @include responsive(lg) {
          max-width: 759px;
        }
      }
    }

    .containerBody{
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 24px;
    
      @include responsive(lg) {
        grid-template-columns: auto 584px;
        column-gap: 12px;
      }
    
      @include responsive(pc) {
        grid-template-columns: auto 675px;
        column-gap: 36px;
      }
    
      @include responsive(xxl) {
        grid-template-columns: auto 934px;
      }
    
      .bodyContent {
        @include responsive(lg) {
          padding-bottom: 64px;
        }
    
        @include responsive(xxl) {
          padding-bottom: 0;
        }
    
        a {
          margin-bottom: 32px;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0;
          padding: 5px 16px;
    
          @include responsive(lg) {
            margin-bottom: 0;
          }
        }
      }
    
      .bodyImage {
        display: flex;
        justify-content: flex-start;

        .imageWrap{
          position: relative;
          width: 100%;
          height: 100%;
          margin-bottom: 0;

          img {
            height: 100%;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 25px 50px 0 rgb(8 24 35 / 25%);
            object-fit: contain;

            @include responsive(lg) {
              border-radius: 4px 0 0;
            }
            @include responsive(sm) {
              border-radius: 4px;
            }
            
            @include responsive(xxl) {
            width: 80%;
            }
          }
    
          .playBtn{
            position: absolute;
            z-index: 10;
            top: 50%;
            left: 50%;
            background-color: transparent;
            border: none;
          
            &:hover{
              cursor: pointer;
            }
          }
        }  
      }
    }
  }
}

.jumbotron {
  background-color: #f9f9fa;
  padding: 16px;

  @include responsive(md) {
    padding: 24px;
  }
}





.containerBadge {
  display: inline-block;
  background-color: $primary;
  color: white;
  border-radius: 4px;
  padding: 1px 8px;
  font-size: 12px;
}

.contentTitle {
  color: var(--gray-10);
  margin-bottom: 34px;
  font-size: 36px;
  font-weight: bold;
  


  @include responsive(sm) {
    font-size: 56px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -2px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
}

.contentHeader{
  color: var(--gray-9);
  margin-bottom: 34px;
  font-size: 25px;

  // font-weight: bold;

  @include responsive(sm) {
    font-size: 38px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -2px;
    margin-bottom: 16px;
  }
}



.contentText {
  color: $text-blue-dark-lightest;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}





.ctaWrapper {
  max-width: 200px;
}

.headIcon {
  display: none;
  font-size: 112px;
  color: var(--gray-5);
  padding: 0;
  margin: 22px 0;

  svg{
    color: var(--gray-5);
    width: 112px;
    height: 112px;

  }
  @include responsive(lg) {
    display: block;
    margin-right: 33px;
  }
}