.timelineWrapper {
  background: #f9f9fa;
  border: 1px solid #e1e3e5;
  border-top: none;
  border-radius: 0 0 4px 4px;

  :global{

    .ant-timeline {
      padding: 24px 16px 0;
      max-height: 588px;
      overflow-y: scroll;

      .ant-timeline-item-tail {
        top: 20px;
        height: calc(100% - 40px);
        border-left: 1px solid #e1e3e5;
      }

      .ant-timeline-item {

        &.ant-timeline-item-last {

          .ant-timeline-item-tail {
            display: block;
          }
        }


        .ant-timeline-item-head {
          background-color: transparent;
        }
      }
    }
  }

}

.logsTimelineWrapper {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 24px;
  min-width: 360px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f9f9fa;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #050b30;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(240 240 240);
  }
}

.header {
  background-color: #050b30;
  padding: 16px;

  h3 {
    color: white;
    margin: 0;
    font-size: 20px;
  }
}



.timelineItemHeader {
  color: var(--grey-9);

  h4 {
    transition: color 0.2s;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
    font-family: Inter,sans-serif;
  }

  p {
    transition: color 0.2s;
    margin-bottom: 8px;
    font-size: 16px;
    font-family: Inter,sans-serif;

  }

  &:hover{
    color: var(--ant-primary-color);

    h4,p{
      color: var(--ant-primary-color);
    }
  }
}

.timelineItemDate {
  display: block;
  color: var(--grey-8);
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: Inter,sans-serif;
}

.more {
  text-align: center;
  padding-bottom: 12px;
  margin-top: 12px;
}
