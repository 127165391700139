// TODO unify with textTypography in theboat_db, theboat_landing

@mixin text-typography($family, $size, $style, $weight, $height, $spacing, $align) {
  font-size: $size;
  font-style: $style;
  font-weight: $weight;
  line-height: $height;
  letter-spacing: $spacing;
  text-align: $align;
}

