@import '../../styles/variables';
@import '../../styles/responsive';

/* stylelint-disable no-descending-specificity */

.container {
  padding: 0 16px;
  padding-top: 80px;
  overflow: hidden;
  overflow-x: scroll;

  @include responsive(sm) {
    padding: 0 24px;
    padding: 80px;
  }

  .containerContent {
    text-align: center;

    .contentTitle {
      color: $text-blue-darker;
      margin-bottom: 16px;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0;
      text-align: center;

      @include responsive(md) {
        font-size: 46px;
        line-height: 54px;
      }
    }

    .contentText {
      color: $grey-7;
      margin: 0;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.4px;
      text-align: center;
    }
  }

  .notAvailableIconStyles{
    color: red;
  }

  .containerTables {
    margin-top: 40px;
    border: 2px solid var(--gray-4);
    border-radius: 6px 6px 0 0;
    min-width: 1000px;

    .containerHead {
      position: sticky;
      top: calc(var(--tbd-header-height) + 1px);

      .headerTableCell{
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      @include responsive(md) {
        position: initial;
      }
    }

    .containerTableButtons {

      tbody {

        tr {
          display: flex;
          flex-direction: column;

          @include responsive(sm) {
            display: table-row;
          }

          td {
            padding-bottom: 0 !important;

            a {
              margin-bottom: 0;
              font-size: 13px;
              padding: 0;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              @include responsive(sm) {
                margin-bottom: 24px;
              }

              @include responsive(lg) {
                height: 40px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    table {
      table-layout: fixed;
      width: 100%;

      thead {


        tr {
          border: 2px solid var(--gray-4);

          th {
            // border-bottom: 1px solid $blue-lightest;
            padding: 16px 12px;

            div {
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                font-size: 24px;
                margin-right: 13px;
              }

              p {
                margin: 0;
                color: $text-blue-dark-light;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0;
                text-align: center;        

                @include responsive(md) {
                  font-size: 24px;
                  line-height: 32px;
                }
              }
            }

            &[colspan = '2'] {
              padding-left: 24px;

              div {

                p {
                  font-size: 20px;
                  line-height: 28px;
                }
              }
            }

            &:nth-child(1) {

              div {
                justify-content: flex-start;
              }
            }
          }
        }
      }


      tbody {

        tr {
          border-bottom: 1px solid $blue-lightest;

          td {
            padding: 5px 24px 0;

            @include responsive(md){
              padding: 24px;
            }

            border-bottom: 2px solid var(--gray-4);

            svg {
              font-size: 23px;
            }

            p {
              color: $grey-7;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0;
              text-align: left;  

              a {
                color: $primary;
                border-bottom: 1px solid $primary;
              }
            }

            &.tableTdCenter {
              text-align: center;
              border-bottom: unset;
              @include responsive(md){
                border-bottom: 2px solid var(--gray-4);
              }

              p {
                color: $text-blue-dark-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0;
                text-align: center;        

                span {
                  font-weight: 400;
                  color: $grey-7;
                }
              }
            }

            h4 {
              color: $text-blue-dark-light;
              margin: 7px;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;        
            }

            .badge {
              display: inline-block;
              background-color: $primary;
              color: white;
              border-radius: 32px;
              padding: 1px 8px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0;
              text-align: center;        
            }

            table {

              tbody {

                tr {

                  td {
                    padding-bottom: 24px;
                  }
                }
              }
            }
          }

          &:last-child {
            border-bottom: 0;

            td {
              padding-bottom: 24px;
            }
          }

          @include responsive(md) {
            border-bottom: unset;
          }
        }
      }

    

      &:nth-child(1) {

        thead {

          tr {

            th {

              &:last-child {
                border-top-right-radius: 6px;
              }
            }
          }
        }
      }
    }

   
  }
}
