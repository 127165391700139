/* stylelint-disable selector-class-pattern */
@import '../../../../../styles/responsive';
@import '../../../../../styles/variables';

.container {
  border-bottom: 1px solid var(--gray-4);
  padding-bottom: 40px;
  margin-bottom: 40px;

  p{
    line-height: 24px;
    letter-spacing: 0;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  &:last-child {
    margin-bottom: 24px;
  }
}

.containerTitle {
  color: var(--gray-9);
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: Inter, sans-serif;
}

.containerDate {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: var(--gray-8);

  span {
    margin-right: 10px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .containerText {
    margin: 0;
    color: var(--gray-9);
    line-height: 24px;
    letter-spacing: 0;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

}

.containerSubtitle {
  color: var(--gray-8);
  margin-bottom: 26px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  font-family: Inter,sans-serif;
}

.logDescriptionTypeTitle{
  padding-left: 16px;
  margin-bottom: 19px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  font-family: Inter, sans-serif;
  letter-spacing: 0;
  text-align: left;
  border-left: 4px solid black;

  
  &:global(.logDescriptionType__added){
    color: var(--green-6);
    border-color: var(--green-6);
  }

  &:global(.logDescriptionType__updated){
    color: var(--geekblue-6);
    border-color: var(--geekblue-6);
  }

  &:global(.logDescriptionType__fixed){
    color: var(--orange-6);
    border-color: var(--orange-6);
  }
}

.descriptionText{
  color: var(--gray-8);
  line-height: 24px;
  letter-spacing: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;

  li{
    line-height: 24px;
    letter-spacing: 0;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

}