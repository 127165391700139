@import '../../styles/variables';
@import '../../styles/responsive';

.container {
  background-color: #f9f9fa;
  width: 380px;
  margin: 12px auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgb(8 24 35 / 6%), 0 4px 6px rgb(8 24 35 / 10%);
  transition: box-shadow 0.4s;
  max-width: 95%;
  height: calc(100% - 15px);

  &:hover {
    box-shadow: 0 20px 25px rgb(8 24 35 / 10%), 0 10px 10px rgb(8 24 35 / 4%);
  }

  @include responsive(xxl){
    width: 420px;
  }

  .containerContent {
    padding: 0 24px 24px;
    
    .containerTitleWrapper{
      border-bottom: 3px solid #a599ee; 
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .contentTitle {
      color: $text-blue-dark-light;
      margin-bottom: 24px;
      text-transform: capitalize;
      font-family: Inter, sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 0;
      text-align: start;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 60px;

      svg {
        height: 38px;
        width: 38px;
        margin-right: 10px;
      }
    }

    .contentText {
      color: $text-blue-dark-lighter;
      @include responsive(xl) {
        margin: auto;
      }

      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      text-align: start;
      min-height: 52px;
    }

    .contentPrice {
      color: $text-blue-dark-light;
      margin-bottom: 32px;
      margin-top: 32px;
      font-family: Inter, sans-serif;
      font-size: 56px;
      font-style: normal;
      font-weight: 600;
      line-height: 64px;
      letter-spacing: 0;
      text-align: start;
      display: flex;
      gap: 8px;
    }

    .contentTime {
      color: $text-blue-dark-lightest;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      text-align: center;
      width: 100px;
      min-height: 44px;
    }

    a {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }

  .containerItems {
    padding: 0 24px;
    margin-left: 20px;

    img {
      display: block;
      margin: auto;
      margin-bottom: 24px;
    }

    .containerItem {
      padding-bottom: 16px;
      color: $text-blue-dark-lightest;

      p {
        color: currentColor;
        margin: 0;
        margin-left: 15px;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;

      }

      svg {
        font-size: 17px;
      }
    }

    .containerItemUnselected {
      text-decoration: line-through;
    }

    &.containerItemsApp {
      flex: 1;
    }
  }

  .featuresContainer{
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-8);
    margin-left: 12px;
    margin-right: 12px;

    li{
      margin-bottom: 12px;
      line-height: 24px;
    }
  }
}

.cardFooter{
  padding: 0 24px 40px;
  margin-top: auto;
}

.getStartedButton{
  color: var(--blue-6);
  border-color: var(--blue-6);
  text-transform: uppercase;
  font-weight: bold;
}

.footerActionButton, .actionButton{
  margin-top: 32px;
}

.actionButton{
  padding: 0 24px;
}


.footerButtonMore {
  margin-top: 24px;
  font-weight: bold;
}